<template>
    <div class="close-header-title no-con">
        <section class="privacy-section">
            <div class="container">
                <div class="main-heading">
                    <h1>FAQ</h1>
                    <p>Want to know more? Learn how to make the most of our site</p>
                </div>
                <div class="content-section center">
                    <h2>How does cheapcomforts work?</h2>
                    <p>cheapcomforts brings together all the short term rentals, hotels, and RVs from the top booking
                        sites, making it easy to find the perfect rental. Select rentals can be booked directly on
                        cheapcomforts while others can be booked with our booking partners. All bookings are serviced
                        by our booking partners.</p>

                    <h2>How much does it cost to use cheapcomforts?</h2>
                    <p>cheapcomforts is completely free to use. Our booking partners may include fees, which are always
                        included in the pricing we display so there are no surprises. These fees are standard to our
                        booking partners, cheapcomforts does not charge any additional fees or modify the price in any
                        way.</p>

                    <h2>How can I report errors or bugs on the site?</h2>
                    <p>Please Contact us to flag any errors or issues you find. We read every message and will do our
                        best to squash whatever bugs you send our way.
                    </p>
                    <h2>How can I monitor the best deals with price alerts?
                    </h2>
                    <p>After signing up for a price alert you'll receive emails containing deals and newly available
                        properties so that you can find the perfect rental for your upcoming trip. These emails may be
                        sent daily, or as prices and availability change, until the check-in date of your alert. You can
                        unsubscribe at any time using a link at the bottom of the price alerts emails, or if you have an
                        account you can manage your price alert subscriptions on your account page.</p>
                    <p>To signup, simply search and turn on a price alert using the "Track prices" option on the page.
                        In some instances on our site that button is labeled with a bell.</p>
                    <h2>I didn't receive any confirmation of my booking. How can I get those details?
                    </h2>
                    <p>There are two ways to make a booking through cheapcomforts: on our site or on a partner's site.
                    </p>
                    <p>If you booked directly on cheapcomforts you should have received two emails - one from
                        cheapcomforts and one from our booking partner who will handle your stay. If you're missing one
                        or both please check your spam folder. The booking information can also be found on your account
                        page. you can't find the emails and don't have an account, reach out to us and we'll help get
                        you your information.</p>
                    <h2>How can I get a receipt or invoice for my booking?
                    </h2>
                    <p>Documentation should have been sent to the provided email address shortly after booking. It is
                        also available on your account page. If you did not receive this documentation and do not have
                        an account, please confirm it is missing and request followup.</p>
                    <h2>Why did you charge me?
                    </h2>
                    <p>cheapcomforts does not directly process payments and should not be showing up on your credit
                        card statement. Our partners will only charge you if you make a booking. If you booked on the
                        cheapcomforts site your payment would have been processed by RedAwning. If you booked on a
                        partner site after visiting cheapcomforts that partner would have processed your payment.</p>
                    <h2>How does cheapcomforts use my information when I sign up for an account?
                    </h2>
                    <p>We use your information to provide the best travel experience possible on cheapcomforts. With
                        your consent we may also reach out to you via email about price alerts, trip inspiration, or
                        other travel related content.</p>
                    <h2>Can I list my property on cheapcomforts?
                    </h2>
                    <p>Yes! List your rental with any of our booking partners and we will feature it on our site within
                        a couple of days. More details can be found on our listing page.
                    </p> 
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import API from '@/api';
import axios from 'axios';
import Vue from 'vue';

import { Loader } from '@googlemaps/js-api-loader';
export default {
    name: 'faqs',
    components: {
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {
    },
    computed: {
    },
    created() {
    },
}
</script>
<style>
h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 5px !important;
}
</style>